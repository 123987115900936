import wixCustomElementsRegistry from 'wix-custom-elements'
import fastdom from 'fastdom'
import ResizeObserver from 'wix-resize-observer-polyfill'
import { ViewerModel } from '@wix/thunderbolt-symbols'
import { instance as biService } from '../bi-module/instance'
import { applyPolyfillsIfNeeded } from './polyfills'

const initCustomElements = async (viewerModel: ViewerModel) => {
	if (!process.env.browser) {
		return
	}

	await applyPolyfillsIfNeeded()

	const resizeService = {
		init: (callback: any) => new ResizeObserver(callback),
	}

	const windowResizeService = {
		init: (callback: any) => window.addEventListener('resize', callback),
	}

	const getDevicePixelRatio = () => {
		const isMSMobileDevice = /iemobile/i.test(navigator.userAgent)
		if (isMSMobileDevice) {
			return Math.round(
				window.screen.availWidth / (window.screen.width || window.document.documentElement.clientWidth)
			)
		}
		return window.devicePixelRatio
	}

	const isExperimentOpen = (experiment: string) => Boolean(viewerModel.experiments[experiment])

	const environmentConsts = {
		staticMediaUrl: viewerModel.media.staticMediaUrl,
		mediaRootUrl: viewerModel.media.mediaRootUrl,
		experiments: {},
		isViewerMode: true,
		devicePixelRatio: getDevicePixelRatio(),
	}

	const wixCustomElements = wixCustomElementsRegistry.init({ resizeService, windowResizeService })
	const services = {
		mutationService: fastdom,
		biService,
		isExperimentOpen,
	}
	wixCustomElements.defineWixImage(services, environmentConsts)
	wixCustomElements.defineWixBgImage(services, environmentConsts)
	wixCustomElements.defineWixBgMedia(services, environmentConsts)
	wixCustomElements.defineWixVideo(services, {
		...environmentConsts,
		staticVideoUrl: viewerModel.media.staticVideoUrl,
	})
	window.initCustomElements = () => wixCustomElements.defineWixIframe(services, environmentConsts)
	wixCustomElements.defineWixDropdownMenu(services, environmentConsts)
}

initCustomElements(window.viewerModel)
